import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';
import { parse } from 'query-string';
import { navigate } from 'gatsby';

import Loading from './Loading';
import useApplyDiscountCode from './Cart/useApplyDiscountCode';

const DiscountRoute = ({ discountCode, location }) => {
  const { applyDiscountCode, loading } = useApplyDiscountCode();
  useEffect(() => {
    const { redirect } = parse(location.search);
    if (!discountCode) {
      return navigate('/');
    }
    const applied = applyDiscountCode(discountCode);
    if (redirect) {
      navigate(redirect, {
        replace: true,
        state: applied
          ? {
              discountApplied: discountCode,
            }
          : {},
      });
    } else {
      navigate('/', {
        replace: true,
        state: applied
          ? {
              discountApplied: discountCode,
            }
          : {},
      });
    }
  }, [discountCode]);
  return (
    <Box margin="xlarge" pad="xlarge" align="center" justify="center" fill>
      {discountCode && (
        <Heading level={4}>Applying Discount Code {discountCode}</Heading>
      )}
      {loading && <Loading size="large" />}
    </Box>
  );
};

DiscountRoute.propTypes = {
  location: PropTypes.object,
  discountCode: PropTypes.string,
};

export default DiscountRoute;
