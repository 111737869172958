import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';

import DiscountRoute from '../../components/DiscountRoute';

const DiscountPage = ({ location }) => {
  return (
    <>
      <Router basepath="/discount">
        <DiscountRoute path="/test" location={location} />
        <DiscountRoute path="/:discountCode" location={location} />
      </Router>
    </>
  );
};

DiscountPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default DiscountPage;
